import { Box, Button, Typography } from '@mui/material';
import { ChevronRight } from '@mui/icons-material';

const Doc = ({ title, description, icon, href }: any) => {
  return (
    <Box
      boxShadow="0px 4px 10px 1px rgba(12, 42, 53, 0.10)"
      maxWidth={{ md: '570px', xs: 'none' }}
      width="100%"
      p={{ md: 5, xs: 3 }}
      borderRadius={2}
    >
      <img src={icon} alt="" height="40px" width="auto" />
      <Typography variant="h4" fontWeight={500} my={2}>
        {title}
      </Typography>
      <Typography mb={4}>{description}</Typography>

      <Button
        color="primary"
        variant="text"
        sx={{ p: 0, fontWeight: 700 }}
        endIcon={<ChevronRight color="inherit" />}
        href={href}
        target="_blank"
      >
        Explore Our Docs
      </Button>
    </Box>
  );
};

export default Doc;
