import React, { FC } from 'react';
import { Stack, Typography } from '@mui/material';

const Content: FC = () => (
  <>
    <Typography variant="h4" mb={3} fontWeight={700}>
      Terms and Conditions
    </Typography>
    <Stack spacing={3}>
      <Typography variant="body1" color="textSecondary">
        These Terms and Conditions apply to You, the User of this Platform
        (including any sub-domains, unless expressly excluded by their own terms
        and conditions), and Zinari Technologies Nigeria Limited, the Owner and
        Operator of ZinariPay with url: https://pay.zinari.io/ and other
        applications which ZinariPay may be used in (‘Platform’).
      </Typography>

      <Typography variant="h4" mb={3} fontWeight={700}>
        1. Definitions
      </Typography>

      <Typography variant="body1" color="textSecondary">
        “Content” means any text, image, audiovisual or other information
        published on this platform.
      </Typography>
      <Typography variant="body1" color="textSecondary">
        “Materials” means any information or documentation that we may provide
        to You in connection with Your use of the products including
        documentation, data, information developed any use and other
      </Typography>

      <Typography variant="body1" color="textSecondary">
        “Parties” means both You (the user of this Platform) and the Owner of
        this Platform.
      </Typography>

      <Typography variant="body1" color="textSecondary">
        “Service” means ZinariPay including all pages, subpages, all blogs,
        forums and other connected internet content whatsoever.
      </Typography>

      <Typography variant="body1" color="textSecondary">
        “Terms” means these terms and conditions..
      </Typography>

      <Typography variant="h4" mb={3} fontWeight={700}>
        2. About This Platform
      </Typography>

      <Typography variant="body1" color="textSecondary">
        This Platform comprises various web pages operated by Zinari
        Technologies Nigeria Limited.
      </Typography>
      <Typography variant="body1" color="textSecondary">
        This service is offered to You upon Your acceptance of the Terms,
        Conditions and Notices hereinafter contained. Your use of this service
        constitutes Your agreement to all the Terms contained herein.
      </Typography>

      <Typography variant="h4" mb={3} fontWeight={700}>
        3. Agreement
      </Typography>

      <Typography variant="body1" color="textSecondary">
        3.1. By using this Platform, You acknowledge that You have reviewed,
        considered the terms of this Agreement and understand same, and agree to
        be bound by it. If You do not agree with these Terms or do not intend to
        be bound by it, you must quit the use of this Platform immediately. In
        addition, when using this service, You shall be subject to any posted
        guidelines or rules applicable to such services. Accordingly, any
        participation in this Service shall constitute acceptance of this
        Agreement.
      </Typography>
      <Typography variant="body1" color="textSecondary">
        3.2. By using this Platform and agreeing to these Terms, You represent
        and warrant that You have the legal capacity to accept these terms.
      </Typography>

      <Typography variant="h4" mb={3} fontWeight={700}>
        4. Acceptable Use
      </Typography>

      <Typography variant="body1" color="textSecondary">
        4.1. We hereby grant you the license to use our Service for your
        commercial use to retrieve, display and view the content on a computer
        and phone screen.
      </Typography>
      <Typography variant="body1" color="textSecondary">
        4.2. The license created under these Terms is limited, non-exclusive,
        non-transferable and revocable.
      </Typography>
      <Typography variant="body1" color="textSecondary">
        4.3.You agree that you will not use the contents or materials for any
        other purpose which may be contrary to your license to the use of this
        Service.
      </Typography>
      <Typography variant="body1" color="textSecondary">
        4.4. Any unauthorized use by you shall terminate the permission or
        license granted by this Platform.
      </Typography>

      <Typography variant="h4" mb={3} fontWeight={700}>
        5. Prohibited Use
      </Typography>

      <Typography variant="body1" color="textSecondary">
        5.1. You are expressly prohibited from collecting, downloading, copying
        or otherwise communicating with other Users from this Platform.
      </Typography>
      <Typography variant="body1" color="textSecondary">
        5.2. You agree not to use this service in the following manner:
      </Typography>
      <Typography variant="body1" color="textSecondary">
        5.2.1. To harass, abuse or threaten others or otherwise violate any
        person’s legal rights as prohibited in the Computer Fraud and Abuse Act
        (“CFAA”).
      </Typography>
      <Typography variant="body1" color="textSecondary">
        5.2.2. To perpetrate fraud.
      </Typography>
      <Typography variant="body1" color="textSecondary">
        5.2.3. To create or transmit unnecessary spam or bulk communications to
        any person or URL.
      </Typography>
      <Typography variant="body1" color="textSecondary">
        5.2.4. To post, transmit or cause to be posted or transmitted any
        communication or solicitation designed to obtain password, account, or
        private information of other Users or person which constitutes identity
        theft or phishing under the Cybercrimes (Prohibition, Prevention, etc)
        Act, 2015 in Nigeria and the General Data Protection Regulation
        (“GDPR”).
      </Typography>
      <Typography variant="body1" color="textSecondary">
        5.2.5. To use automated means (such as but not limited to robot, spider)
        to access this Service without obtaining the prior consent of the Owner.
      </Typography>
      <Typography variant="body1" color="textSecondary">
        5.2.6. To engage in or create unlawful gambling, sweepstakes or scheme
        which may contravene Nigeria’s National Lottery Act and Unlawful
        Internet Gambling Enforcement Act.
      </Typography>
      <Typography variant="body1" color="textSecondary">
        5.2.7. To use this service in a way that disrupts or limits other
        users&apos; access to this Platform.
      </Typography>
      <Typography variant="body1" color="textSecondary">
        5.2.8. To disseminate computer viruses or other harmful software in
        violation of the Cybercrimes (Prohibition, Prevention, etc) Act, 2015.
      </Typography>
      <Typography variant="body1" color="textSecondary">
        5.2.9. To engage in any activity that infringes the intellectual
        property rights of the Owner or any third party prohibited under the
        Nigerian Copyright Act.
      </Typography>
      <Typography variant="body1" color="textSecondary">
        5.3. Additionally, you agree that you will not do the following:
      </Typography>
      <Typography variant="body1" color="textSecondary">
        5.3.1. Interfere or attempt to interfere with the proper functioning of
        this Platform.
      </Typography>
      <Typography variant="body1" color="textSecondary">
        5.3.2. Bypass any measures we may use to prevent or restrict access to
        this Platform.
      </Typography>
      <Typography variant="body1" color="textSecondary">
        5.3.3. Interfere with or circumvent the security features of this
        Service, violating applicable digital security standards.
      </Typography>
      <Typography variant="body1" color="textSecondary">
        5.3.4. Damage, disable, overburden or impair this Service or any other
        person’s use of this Service.
      </Typography>
      <Typography variant="body1" color="textSecondary">
        5.3.5. Use this Service contrary to the applicable laws and regulations
        in Nigeria and internationally, in a manner that causes, or may cause
        harm to this Platform, any individual, or business entity.
      </Typography>
      <Typography variant="body1" color="textSecondary">
        5.4. We reserve the right to review all content posted by the Users on
        this Platform. We also reserve the right to terminate Your use of the
        Service for violating any of the prohibited uses listed above.
      </Typography>

      <Typography variant="h4" mb={3} fontWeight={700}>
        6. Intellectual Property Ownership
      </Typography>

      <Typography variant="body1" color="textSecondary">
        6.1. You agree that we retain ownership of all Content on this Platform
        such as the text, graphics, video, software, data, page layout, images,
        and any other information capable of being stored in a computer, other
        than the contents uploaded by users.
      </Typography>
      <Typography variant="body1" color="textSecondary">
        6.2. Nothing on this Platform shall be construed as granting any license
        or right to use any trademark or logo displayed on the Platform without
        obtaining the prior written consent of the Owner.
      </Typography>
      <Typography variant="body1" color="textSecondary">
        6.3. You hereby agree not to reproduce or distribute the Owner’s
        intellectual property or use the intellectual property for any unlawful
        purpose.
      </Typography>

      <Typography variant="h4" mb={3} fontWeight={700}>
        7. Use Account
      </Typography>

      <Typography variant="body1" color="textSecondary">
        7.1. You will be required to register with us to have access to our
        service.
      </Typography>
      <Typography variant="body1" color="textSecondary">
        7.2. You will be required to provide certain personal information which
        includes but is not limited to your Name, Username, Email, and Password.
        The information provided must be correct and accurate.
      </Typography>
      <Typography variant="body1" color="textSecondary">
        7.3. This personal information must not be disseminated to anyone, and
        when you discover that your information has been compromised, you agree
        to notify us immediately.
      </Typography>
      <Typography variant="body1" color="textSecondary">
        7.4. You acknowledge that you are responsible for maintaining the
        confidentiality of your passwords, personal information, or other
        account identifiers which you choose and all activities under your
        account, and that the Owner will not be liable for any damage caused by
        your negligence.
      </Typography>
      <Typography variant="body1" color="textSecondary">
        7.5. The Owner reserves the right to terminate your account where you
        have provided false or inaccurate information.
      </Typography>

      <Typography variant="h4" mb={3} fontWeight={700}>
        8. Payment
      </Typography>

      <Typography variant="body1" color="textSecondary">
        You agree to pay the required bank charges and taxes while making
        payment with our Service.
      </Typography>

      <Typography variant="h4" mb={3} fontWeight={700}>
        9. Privacy Policy
      </Typography>

      <Typography variant="body1" color="textSecondary" fontWeight={600}>
        9.1. Information we collect and use:
      </Typography>
      <Typography variant="body1" color="textSecondary">
        For registered users: You provide personal information such as your
        name, username, and email address if you register for an account with
        our service.
      </Typography>

      <Typography variant="body1" color="textSecondary">
        We may also require other information in relation but not limited to the
        following:
      </Typography>

      <Typography variant="body1" color="textSecondary">
        <b>For unregistered users:</b> We will collect passive information from
        all registered and unregistered users. This information includes
        cookies, IP address information, location information, and certain
        browser information.
      </Typography>

      <Typography variant="body1" color="textSecondary">
        <b>User Experience:</b> From time to time, we may also request certain
        personal data that may be necessary to improve our service.
      </Typography>

      <Typography variant="body1" color="textSecondary">
        We may also receive information from external websites/applications you
        use to access our service through various web technologies such as
        cookies, log files, and clear gifs, with your permission.
      </Typography>

      <Typography variant="body1" color="textSecondary">
        <b>9.2. How we use the Information:</b> We use your information to
        provide personalized service to you. We also use it to help monitor and
        improve the service we offer. We also track certain information received
        to improve our marketing. We will only use your personal data for the
        purpose it is intended for and with your permission.
      </Typography>

      <Typography variant="body1" color="textSecondary">
        <b>9.3. How we protect your information:</b> We will use administrative
        security measures to reduce the risk of loss or misuse. While the
        security of your information is paramount to us, we cannot guarantee its
        absolute security. If you choose to terminate your account, your
        personal information will be deleted immediately.
      </Typography>

      <Typography variant="h4" mb={3} fontWeight={700}>
        10. Data Protection
      </Typography>
      <Typography variant="body1" color="textSecondary">
        To the extent that the nature of the Services requires the Owner to
        process Personal Data, the Owner will process all Personal Data in
        accordance with, where and as applicable, the Nigeria Data Protection
        Regulation, 2019 (NDPR), General Data Protection Regulation 2016/679
        under European Union Law (“GDPR”) on data protection and privacy, the
        California Consumer Privacy Act (“CCPA”), data protection legislation of
        the United Kingdom, and all other applicable data protection laws
        (collectively, the “Data Protection Laws”). “Personal Data” will have
        the meaning set forth in Section 1.3 of the NDPR, or as such term is
        defined under the laws of any territory with jurisdiction over this
        Agreement related to the protection of Personal Data
      </Typography>

      <Typography variant="h4" mb={3} fontWeight={700}>
        11. Links to Third Party Sites/Services
      </Typography>
      <Typography variant="body1" color="textSecondary">
        The Platform may contain links to other websites. This linked sites are
        not under our control and You agree that we are not liable for the
        contents of any linked sites.
      </Typography>

      <Typography variant="h4" mb={3} fontWeight={700}>
        12. Electronic Communication
      </Typography>
      <Typography variant="body1" color="textSecondary">
        You consent to receive electronic communications and agree that all
        agreements, notices, disclosures and other communications we provide to
        you electronically via email and on this Platform satisfy any legal
        requirements that communications in writing.
      </Typography>

      <Typography variant="h4" mb={3} fontWeight={700}>
        13. Change and Maintenance of Service
      </Typography>

      <Typography variant="body1" color="textSecondary">
        13.1. You agree that the Owner may alter, vary, amend, or update its
        Services at any time without your consent.
      </Typography>

      <Typography variant="body1" color="textSecondary">
        13.2. You agree that the services may not be available at all times and
        that this may be as a result of maintenance or for any other reason, and
        we shall not be held liable for the failure to provide this Service.
      </Typography>

      <Typography variant="h4" mb={3} fontWeight={700}>
        14. Indemnification
      </Typography>

      <Typography variant="body1" color="textSecondary">
        You hereby agree to indemnify the Owner, its employees, agents and third
        parties from and against all liabilities, cost, demands, cause of
        action, damages and expenses (including reasonable attorney’s fees)
        arising out of Your use or inability to use, any uploads made by You,
        Your violation of any rights of a third party and Your violation of
        applicable laws, rules or regulation.
      </Typography>

      <Typography variant="h4" mb={3} fontWeight={700}>
        15. No Warranties
      </Typography>

      <Typography variant="body1" color="textSecondary">
        15.1. You agree that you use this Platform solely at your own risk as
        the Owner does not warrant the accuracy of the contents on this
        Platform. You assume all risks associated with viewing, reading, or
        downloading the contents of this platform.
      </Typography>

      <Typography variant="body1" color="textSecondary">
        15.2. The Owner expressly disclaims all express and implied warranties,
        such as the warranty of merchantability, as the Owner makes no
        warranties that the Platform or other services will be accurate,
        error-free, secure, or uninterrupted.
      </Typography>

      <Typography variant="body1" color="textSecondary">
        15.3. The Owner makes no warranty about the suitability, reliability,
        availability, timeliness, and accuracy of the information, contents,
        products, services, and other materials contained herein for any
        purpose. The Owner hereby disclaims all warranties and conditions
        regarding the information, software, related graphics, and materials.
      </Typography>

      <Typography variant="body1" color="textSecondary">
        15.4. You also agree that the Owner and its affiliates shall not be
        liable for any direct, indirect, punitive, or consequential damages,
        including but not limited to damages for loss of use, data, or profits,
        the failure to provide services, or any information, software, related
        graphics, and materials obtained through this platform.
      </Typography>

      <Typography variant="h4" mb={3} fontWeight={700}>
        16. Service Interruptions
      </Typography>

      <Typography variant="body1" color="textSecondary">
        The Owner may from time to time interrupt Your access or use of this
        Platform to perform some maintenance or emergency services, and You
        agree that the Owner shall not be held liable for any damage or loss
        which may arise thereof.
      </Typography>

      <Typography variant="h4" mb={3} fontWeight={700}>
        17. Termination and Restriction of Access
      </Typography>

      <Typography variant="body1" color="textSecondary">
        17.1 The Owner reserves the right to terminate or suspend Your account
        for violating the terms of this service.
      </Typography>
      <Typography variant="body1" color="textSecondary">
        17.2. If you register with us, You may terminate this service at any
        time by deleting your registered account from this Platform.
      </Typography>

      <Typography variant="h4" mb={3} fontWeight={700}>
        18. General Provisions
      </Typography>

      <Typography variant="body1" color="textSecondary">
        18.1. Assignment: The Owner shall be permitted to assign or transfer its
        rights and or obligations under this Agreement. However, You shall not
        be permitted to assign or transfer any rights and or obligations under
        this Agreement.
      </Typography>
      <Typography variant="body1" color="textSecondary">
        18.2. Entire Agreement: The Terms and Condition of this Platform
        constitutes the entire Agreement and supersedes any other agreement.
      </Typography>
      <Typography variant="body1" color="textSecondary">
        18.3. Applicable Law: These terms may be governed by and construed in
        accordance with the Laws of the Federal Republic of Nigeria
      </Typography>
    </Stack>
  </>
);

export default Content;
