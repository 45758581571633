import { Box, Divider, Typography } from '@mui/material';

const Fees = ({ fee, description }: any) => {
  return (
    <Box
      borderRadius={'15px'}
      pt={11}
      pb={9}
      bgcolor="secondary.50"
      width="100%"
      textAlign="center"
    >
      <Typography variant="h1" fontWeight={700} mb={8}>
        {fee}
      </Typography>
      <Typography>Processing fee</Typography>
      <Divider sx={{ borderStyle: 'dashed', my: 3 }} />
      <Typography>{description}</Typography>
    </Box>
  );
};

export default Fees;
