import { Box, Button, Grid2 as Grid, Typography } from '@mui/material';
import { ChevronRight } from '@mui/icons-material';

import placeholder from '@image/placeholder.webp';
import { CONSOLE_URL, TEAM_MEMBERS } from '@variable';

const TeamMember = ({ name, role, image, linkedin }: any) => {
  const handleClick = () => {
    window.open(linkedin, '_blank');
  };

  return (
    <Grid
      size={{ xs: 12, md: 3 }}
      onClick={handleClick}
      sx={{ cursor: 'pointer' }}
    >
      <Box
        sx={{
          margin: 'auto',
          p: 2,
          border: '1px solid',
          borderColor: 'primary.50',
          borderTopRightRadius: '15px',
          borderTopLeftRadius: '15px',
          height: '100%',
        }}
        textAlign="center"
      >
        <Box
          sx={{
            background: `url(${image || placeholder}) no-repeat top center`,
            backgroundSize: 'cover',
            borderTopRightRadius: '15px',
            borderTopLeftRadius: '15px',
            width: '100%',
            height: { md: '230px', xs: '350px' },
          }}
        />
        <Typography
          variant="subtitle1"
          fontWeight={600}
          fontSize={'24px'}
          my={1}
        >
          {name}
        </Typography>
        <Typography color="textSecondary" variant="body1">
          {role}
        </Typography>
      </Box>
    </Grid>
  );
};

const MeetTheTeam = () => {
  return (
    <Box
      sx={{
        px: { lg: '100px', xs: 2 },
        py: { lg: 15, xs: 5 },
      }}
    >
      <Box maxWidth={{ md: '478px', xs: 'none' }} mb={5}>
        <Typography variant="h4" fontWeight={500}>
          Meet the ZinariPay Team
        </Typography>
        <Typography mt={2} mb={3} color="textSecondary">
          At ZinariPay, we’re a diverse, close-knit team focused on
          revolutionizing cryptocurrency payments. Every day, we learn and grow
          as we build lasting solutions to make payments seamless, secure, and
          global.
        </Typography>

        <Button
          variant="text"
          color="secondary"
          endIcon={<ChevronRight color="inherit" />}
          href={CONSOLE_URL}
          target={'_blank'}
        >
          Join us
        </Button>
      </Box>

      <Grid container spacing={2}>
        {TEAM_MEMBERS.map((value) => (
          <TeamMember {...value} key={value.name} />
        ))}
      </Grid>
    </Box>
  );
};

export default MeetTheTeam;
