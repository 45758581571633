import React, {
  FC,
  MouseEvent,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import {
  AppBar,
  Box,
  Button,
  IconButton,
  Menu,
  MenuItem,
  Stack,
  Toolbar,
  useMediaQuery,
} from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import { Link as RouterLink, useLocation, useNavigate } from 'react-router-dom';
import { useTheme } from '@mui/material/styles';

import { CONSOLE_URL } from '@variable';

const MENU_ITEMS = [
  { label: 'Why Zinari', link: '/why-zinari' },
  { label: 'Solutions', link: '/solutions' },
  { label: 'PayLink', link: '/paylink' },
  { label: 'Pricing', link: '/pricing' },
  { label: 'Developers', link: '/developers' },
  { label: 'Company', link: '/company' },
  { label: 'Support', link: '/support' },
];

const Header: FC = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = useState<any>(null);
  const { pathname } = useLocation();
  const [isLight, setIsLight] = useState(pathname !== '/');
  const headerRef = useRef<HTMLElement | null>(null);
  const isLanding = pathname === '/';

  useEffect(() => {
    setIsLight(!isLanding);
  }, [isLanding]);

  const handleMenuOpen = (event: MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const eventHandler = useCallback(() => {
    const landingHero = document.getElementById('LandingHero');
    const isPastHero =
      window?.scrollY >
      (landingHero?.offsetHeight || 0) -
        (headerRef?.current?.offsetHeight || 0);

    if (isLanding) setIsLight(isPastHero);
  }, [isLanding]);

  useEffect(() => {
    setTimeout(() => {
      window.addEventListener('scroll', eventHandler);

      return () => {
        window.removeEventListener('scroll', eventHandler);
      };
    }, 200);
  }, [eventHandler]);

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  return (
    <AppBar
      position="fixed"
      color="transparent"
      elevation={0}
      sx={{
        backgroundColor: isLight ? 'common.white' : 'primary.900',
        transition: 'all 500ms ease-in-out',
      }}
      ref={headerRef}
    >
      <Toolbar sx={{ px: { lg: '100px', xs: 2 } }}>
        <Stack
          flexDirection="row"
          justifyContent="space-between"
          alignItems="center"
          width={'100%'}
        >
          <Button
            onClick={() => navigate('/')}
            sx={{
              p: 0,
              m: 0,
              minWidth: 0,
              border: 0,
            }}
          >
            <img
              src={isLight ? '/logoPurple.webp' : '/logo.webp'}
              alt="Logo"
              style={{
                height: 'auto',
                maxWidth: 154,
                width: '100%',
              }}
            />
          </Button>

          {/* Desktop Menu */}
          {!isMobile && (
            <Box overflow={'scroll'} sx={{ px: '25px' }}>
              <Stack gap={'43px'} direction={'row'} width={'fit-content'}>
                {MENU_ITEMS.map(({ label, link }) => (
                  <Button
                    key={label}
                    component={RouterLink}
                    to={link}
                    variant={'text'}
                    sx={{
                      color:
                        window.location.pathname === link
                          ? 'secondary.400'
                          : isLight
                          ? 'grey.900'
                          : 'common.white',
                      fontWeight: window.location.pathname === link ? 700 : 500,
                      p: 0,
                      m: 0,
                      minWidth: 0,
                      textUnderlineOffset: 9,
                      whiteSpace: 'nowrap',
                      transition: 'all 500ms ease-in-out',
                    }}
                  >
                    {label}
                  </Button>
                ))}
              </Stack>
            </Box>
          )}

          {!isMobile && (
            <Box minWidth={'176px'}>
              <Button
                variant={'text'}
                sx={{
                  color: isLight ? 'grey.900' : 'common.white',
                  p: 0,
                  m: 0,
                  minWidth: 0,
                  textTransform: 'none',
                  fontWeight: 600,
                }}
                href={CONSOLE_URL}
                target={'_blank'}
              >
                Sign in
              </Button>
              <Box
                sx={{
                  mx: '22px',
                  display: 'inline',
                  color: isLight ? 'primary.50' : 'common.white',
                  transition: 'all 500ms ease-in-out',
                }}
              >
                |
              </Box>
              <Button
                variant={'contained'}
                color={'primary'}
                size={'small'}
                href={CONSOLE_URL}
                sx={{
                  bgcolor: isLight ? 'initial' : 'white !important',
                  color: isLight ? 'common.white' : 'primary.900',
                }}
                target={'_blank'}
              >
                Get Started
              </Button>
            </Box>
          )}

          {/* Mobile Menu */}
          {isMobile && (
            <>
              <IconButton edge="end" aria-label="menu" onClick={handleMenuOpen}>
                <MenuIcon
                  sx={{ color: isLight ? 'secondary.400' : 'common.white' }}
                />
              </IconButton>
              <Menu
                anchorEl={anchorEl}
                anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                keepMounted
                transformOrigin={{ vertical: 'top', horizontal: 'right' }}
                open={Boolean(anchorEl)}
                onClose={handleMenuClose}
              >
                {MENU_ITEMS.map(({ label, link }) => (
                  <MenuItem
                    key={label}
                    component={RouterLink}
                    to={link}
                    onClick={handleMenuClose}
                    sx={{
                      color:
                        window.location.pathname === link
                          ? 'secondary.400'
                          : 'grey.900',
                      textUnderlineOffset: 9,
                      fontWeight: window.location.pathname === link ? 700 : 500,
                    }}
                  >
                    {label}
                  </MenuItem>
                ))}

                <MenuItem
                  component={RouterLink}
                  to={CONSOLE_URL}
                  onClick={handleMenuClose}
                  target={'_blank'}
                >
                  Login
                </MenuItem>
                <MenuItem
                  component={RouterLink}
                  to={CONSOLE_URL}
                  onClick={handleMenuClose}
                  target={'_blank'}
                >
                  Register
                </MenuItem>
              </Menu>
            </>
          )}
        </Stack>
      </Toolbar>
    </AppBar>
  );
};

export default Header;
