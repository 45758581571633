import { Helmet as HelmetWrapper } from 'react-helmet';
import React from 'react';

import logo from './logo.png';

const companyName = 'ZinariPay';
const tagline = 'Blockchain payment infrastructure for businesses.';
const defaultDescription = `ZinariPay offers a secure, scalable, and easy-to-integrate cryptocurrency payment gateway for businesses worldwide. Our platform ensures fast, reliable, and decentralized transactions that empower your business to operate globally with confidence.`;
const defaultKeywords =
  'cryptocurrency payments, blockchain payments, secure payments, decentralized finance, crypto gateway, ZinariPay, fintech, digital payments, scalable payment solutions, zinari, zinari coin, zinari finance, africa, tech, innovation, technology, zinaricoin, zinarifinance, zinapay, zina, original';

const defaultAuthor = `ZinariPay Team`;
const defaultLang = 'en-US';
const defaultViewport = 'width=device-width, initial-scale=1.0';
const websiteUrl = 'https://www.pay.zinari.io/';

const SEO = {
  title: `ZinariPay`,
  description: defaultDescription,
  keywords: defaultKeywords,
  author: defaultAuthor,
  url: websiteUrl,
  image: logo,
  lang: defaultLang,
  viewport: defaultViewport,
};

const Helmet = ({
  title,
  description,
  keywords,
  author,
  children,
}: {
  title?: string;
  description?: string;
  keywords?: string;
  author?: string;
  children?: any;
}) => {
  const finalTitle = `${title || SEO.title} - ${tagline}`;

  return (
    <HelmetWrapper
      title={finalTitle}
      htmlAttributes={{ lang: SEO.lang }}
      meta={[
        { name: 'description', content: description || SEO.description },
        { name: 'keywords', content: keywords || SEO.keywords },
        { name: 'author', content: author || SEO.author },
        { name: 'viewport', content: SEO.viewport },
        { property: 'og:title', content: finalTitle },
        { property: 'og:description', content: description || SEO.description },
        { property: 'og:url', content: SEO.url },
        { property: 'og:image', content: SEO.image },
        { property: 'og:image:type', content: 'image/png' },
        { property: 'og:site_name', content: companyName },
        { property: 'og:type', content: 'website' },
        { name: 'twitter:card', content: 'summary_large_image' },
        { name: 'twitter:image:src', content: SEO.image },
        { name: 'twitter:title', content: finalTitle },
        {
          name: 'twitter:description',
          content: description || SEO.description,
        },
        { name: 'robots', content: 'index, follow' },
        { httpEquiv: 'Content-Type', content: 'text/html; charset=UTF-8' },
        { httpEquiv: 'X-UA-Compatible', content: 'IE=edge' },
        // {
        //   name: "google-site-verification",
        //   content: "YOUR_GOOGLE_SITE_VERIFICATION_CODE",
        // },
        // { name: "msvalidate.01", content: "YOUR_BING_VERIFICATION_CODE" },
        { name: 'theme-color', content: '#003366' },
      ]}
      link={[
        { rel: 'canonical', href: SEO.url },
        { rel: 'icon', href: '/favicon.ico' },
      ]}
      script={[
        {
          type: 'application/ld+json',
          innerHTML: JSON.stringify({
            '@context': 'http://schema.org',
            '@type': 'WebSite',
            url: SEO.url,
            name: companyName,
            potentialAction: {
              '@type': 'SearchAction',
              target: `${websiteUrl}/search?q={search_term_string}`,
              'query-input': 'required name=search_term_string',
            },
          }),
        },
        {
          type: 'application/ld+json',
          innerHTML: JSON.stringify({
            '@context': 'http://schema.org',
            '@type': 'Organization',
            url: SEO.url,
            logo: SEO.image,
            name: companyName,
            contactPoint: {
              '@type': 'ContactPoint',
              email: 'support@zinari.io',
              contactType: 'Customer Support',
            },
          }),
        },
      ]}
    >
      {children}
    </HelmetWrapper>
  );
};
export default Helmet;
