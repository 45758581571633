import { Box, Grid2 as Grid, Typography } from '@mui/material';
import React from 'react';

import count from '@icon/count.webp';

const VALUES = [
  {
    title: 'Create an account',
    description:
      'Create your free Zinari account and select PayLink from the dashboard.',
  },
  {
    title: 'Generate your link',
    description: 'Complete your KYC, Customize and generate your link',
  },
  {
    title: 'Share Link',
    description: 'Share generated directly to your customer’s mail ',
  },
];

const HowCard = ({ index, title, description }: any) => {
  return (
    <Grid size={{ xs: 12, md: 6, lg: 4 }}>
      <Box maxWidth="300px" margin="auto">
        <Box
          sx={{
            background: `url(${count}) no-repeat center center`,
            backgroundSize: 'contain',
            height: '63px',
            width: '63px',
            position: 'relative',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <Typography
            color={'common.white'}
            component={'span'}
            sx={{ position: 'absolute', top: '12px', left: '19px' }}
            fontSize={'20px'}
          >
            {index + 1}
          </Typography>
        </Box>
        <Typography variant="h5" fontWeight={500} mt={3} mb={2}>
          {title}
        </Typography>
        <Typography variant="body1">{description}</Typography>
      </Box>
    </Grid>
  );
};

const HowItWorks = () => {
  return (
    <Box sx={{ px: { lg: '100px', xs: 2 }, py: { lg: 15, xs: 5 } }}>
      <Typography
        variant="h3"
        gutterBottom
        fontWeight={500}
        textAlign="center"
        mb={7}
      >
        How it works
      </Typography>

      <Grid container spacing={2}>
        {VALUES.map((value, index) => (
          <HowCard index={index} key={value.title} {...value} />
        ))}
      </Grid>
    </Box>
  );
};

export default HowItWorks;
