import React, { FC } from 'react';
import { Box, Divider } from '@mui/material';

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

const TabPanel: FC<TabPanelProps> = ({ children, value, index, ...other }) => {
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box
          sx={{
            p: { md: 3, xs: 0 },
            pt: { xs: 0, md: 0 },
          }}
        >
          <Divider sx={{ borderColor: 'primary.200' }} />
          {children}
        </Box>
      )}
    </div>
  );
};

export default TabPanel;
