import React from 'react';
import { Box, IconButton, Stack, Typography } from '@mui/material';
import FacebookIcon from '@mui/icons-material/Facebook';
import InstagramIcon from '@mui/icons-material/Instagram';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import XIcon from '@mui/icons-material/X';

const BottomSection: React.FC = () => {
  return (
    <Stack direction="row" justifyContent="space-between" alignItems="center">
      <Typography variant="body2">
        © {new Date().getFullYear()} ZinariPay. All Rights Reserved.
      </Typography>

      <Box>
        <Box>
          <IconButton href="https://web.facebook.com/zinaripay" target="_blank">
            <FacebookIcon sx={{ color: '#FFF' }} />
          </IconButton>
          <IconButton href="https://instagram.com/zinaripay" target="_blank">
            <InstagramIcon sx={{ color: '#FFF' }} />
          </IconButton>
          <IconButton
            href="https://www.linkedin.com/company/zinaripay/"
            target="_blank"
          >
            <LinkedInIcon sx={{ color: '#FFF' }} />
          </IconButton>
          <IconButton href="https://x.com/zinaripay" target="_blank">
            <XIcon sx={{ color: '#FFF' }} />
          </IconButton>
        </Box>
      </Box>
    </Stack>
  );
};

export default BottomSection;
