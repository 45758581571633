import { Stack } from '@mui/material';

import document from '@icon/document.webp';
import platform from '@icon/platform.webp';

import Doc from './Doc';

const DOUMENTS = [
  {
    title: 'NPM Documentation',
    description:
      'Build powerful applications with ZinariPay’s developer guide. It offers step-by-step instructions to easily integrate secure and efficient cryptocurrency payments, helping your business grow globally.',
    icon: document,
    href: 'https://www.npmjs.com/package/zinari-pay',
  },
  {
    title: 'API Documentation',
    description:
      'Discover the powerful features in ZinariPay’s APIs and learn how they can enhance your product with seamless, secure cryptocurrency payment solutions, helping you scale globally with ease.',
    icon: platform,
    href: 'https://api-docs.zinari.io/',
  },
];

const Docs = () => {
  return (
    <Stack
      direction={{ md: 'row', xs: 'column' }}
      gap={4}
      justifyContent="space-between"
      alignItems="center"
      px={{ lg: '100px', xs: 2 }}
      py={{ md: 15, xs: 5 }}
    >
      {DOUMENTS.map((doc) => (
        <Doc key={doc.title} {...doc} />
      ))}
    </Stack>
  );
};

export default Docs;
