import { Box } from '@mui/material';

import Globally from '@component/Globally';
import Helmet from '@component/Helmet';

import Hero from './components/Hero';
import OurStory from './components/OurStory';
import WhoIsZinari from './components/WhoIsZinari';
import MeetTheTeam from './components/MeetTheTeam';

const Company = () => {
  return (
    <Box pt={{ md: 19, xs: 12 }}>
      <Helmet
        title="Company"
        description="Learn about ZinariPay, our mission, vision, and the passionate team behind our secure and scalable cryptocurrency payment platform. Discover how we are revolutionizing digital payments for businesses worldwide."
        keywords="zinari, zinari coin, zina, zinapay, zinaricoin, zinarifinance, zinaricoin, zinari finance, zinari payment, zinari nigeria, zinari corp, zinari technologies nigeria limited, ZinariPay about us, cryptocurrency payment company, fintech mission, blockchain payment solutions, digital payments"
      />
      <Hero />
      <OurStory />
      <WhoIsZinari />
      <MeetTheTeam />
      <Globally />
    </Box>
  );
};

export default Company;
