import { Outlet as RouterOutlet, useLocation } from 'react-router-dom';
import { Box } from '@mui/material';
import { useEffect } from 'react';

import Header from '@component/Header';
import Footer from '@component/Footer';

const Layout = () => {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return (
    <Box>
      <Header />
      <Box
        component="main"
        sx={{
          flexGrow: 1,
        }}
      >
        <RouterOutlet />
      </Box>
      <Footer />
    </Box>
  );
};

export default Layout;
