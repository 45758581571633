import { Box } from '@mui/material';
import React from 'react';

import DeveloperBack from '@component/DeveloperBack';
import CallToAction from '@component/CallToAction';
import Helmet from '@component/Helmet';

import Hero from './components/Hero';
import Docs from './components/Docs';

const Developers = () => {
  return (
    <Box pt={{ md: 19, xs: 12 }}>
      <Helmet
        title="Developers"
        description="Explore ZinariPay's developer tools including API, CDN, and NPM packages. Seamlessly integrate cryptocurrency payments into your application with our robust developer resources."
        keywords="zinari, zinari coin, zina, zinapay, zinaricoin, zinarifinance, zinaricoin, zinari finance, zinari payment, zinari nigeria, zinari corp, zinari technologies nigeria limited, ZinariPay, API, CDN, NPM, developer tools, cryptocurrency payments, crypto payment integration, blockchain, developer documentation"
      />
      <Hero />
      <DeveloperBack />
      <Docs />
      <CallToAction
        title="Ready to get started?"
        description="Join the thousands of businesses already using ZinariPay It's free to sign up."
        showSalesBtn={false}
      />
    </Box>
  );
};

export default Developers;
