import React, { FC } from 'react';
import { Box, Typography } from '@mui/material';

import Developer from '@component/DeveloperBack';
import payLinkPeople from '@image/paylinkpeople.webp';
import CallToAction from '@component/CallToAction';
import ReadyToGetStarted from '@component/ReadyToGetStarted';

import HowItWorks from './components/HowItWorks';
import Hero from './components/Hero';

const Description = () => {
  return (
    <Typography
      variant="h4"
      color="common.white"
      fontWeight={500}
      maxWidth={'485px'}
      mt={4}
      mb={9}
      lineHeight={'44px'}
    >
      The ideal solution for no-code businesses to effortlessly accept crypto{' '}
      <Box component="span" sx={{ color: 'secondary.400' }}>
        payments
      </Box>
      .
    </Typography>
  );
};

const Image = () => {
  return (
    <Box
      sx={{
        background: `url(${payLinkPeople})`,
        backgroundSize: 'cover',
        backgroundPosition: 'top right',
        width: '100%',
        height: { lg: '510px', xs: '300px' },
        borderRadius: '20px',
        py: 6,
        px: { xs: 2, md: 7 },
        pr: { xs: 2, md: 75 },
      }}
    />
  );
};

const Content: FC = () => (
  <Box pt={{ md: 19, xs: 12 }}>
    <Hero />
    <Developer
      title={'PayLink'}
      description={<Description />}
      image={<Image />}
    />
    <HowItWorks />
    <ReadyToGetStarted />
    <CallToAction
      title="Ready to get started?"
      description="Join the thousands of businesses already using ZinariPay It's free to sign up."
      showSalesBtn={false}
    />
  </Box>
);

export default Content;
