export const COMPANY_GROUP = {
  title: 'Company',
  menuItems: [
    {
      title: 'About',
      to: '/company',
    },
    {
      title: 'Solutions',
      to: '/solutions',
    },
    {
      title: 'Why Zinari',
      to: '/why-zinari',
    },
    {
      title: 'FAQ',
      to: '/company',
    },
    {
      title: 'Support',
      to: '/support',
    },
  ],
};

export const RESOURCES_GROUP = {
  title: 'Resources',
  menuItems: [
    {
      title: 'ZinariPay OpenApi',
      to: 'https://api-docs.zinari.io/',
      target: '_blank',
    },
    {
      title: 'NPM Documentation',
      to: 'https://www.npmjs.com/package/zinari-pay',
      target: '_blank',
    },
    {
      title: 'Integration Guides',
      to: '/developers',
    },
  ],
};

export const LEGAL_GROUP = {
  title: 'Legal',
  menuItems: [
    {
      title: 'Privacy Policy',
      to: '/policy/privacy',
    },
    {
      title: 'Terms of use',
      to: '/policy/terms',
    },
    {
      title: 'Anti-Bribery Anti-Corruption Policy',
      to: '/policy/anti-policy',
    },
    {
      title: 'Anti-Money Laundering Policy',
      to: '/policy/anti-policy',
    },
  ],
};

export const CONTACT_GROUP = {
  title: 'Contact',
  menuItems: [
    {
      title: 'Victoria Island, Lagos, Nigeria',
      to: '',
    },
    {
      title: 'support@zinari.io',
      to: 'mailto:support@zinari.io',
    },
  ],
};
