import {
  Outlet as RouterOutlet,
  useLocation,
  useNavigate,
} from 'react-router-dom';
import { Box, Stack, Typography } from '@mui/material';

import policy from '@image/policy.webp';

const PolicyLayout = () => {
  const { pathname } = useLocation();
  const currentPolicy = pathname
    .replace(/\/policy/, '')
    .replace(/\//, '')
    .toLowerCase();

  const title = (() => {
    switch (currentPolicy) {
      case 'anti-policy':
        return 'Anti-Bribery, Anti-Corruption, and Anti-Money Laundering Policy';
      case 'terms':
        return 'Terms and Conditions';
      default:
        return 'Privacy Policy';
    }
  })();

  const version = (() => {
    return 'Ver 1.0 Last updated 6th September, 2024.';
  })();

  const navigate = useNavigate();

  return (
    <Box>
      <Stack
        sx={{
          px: 2,
          mt: 18,
          width: '100%',
          alignItems: 'center',
          backgroundSize: 'cover',
          justifyContent: 'center',
          height: { md: '390px', xs: '280px' },
          background: `url(${policy}) no-repeat top right`,
        }}
      >
        <Box textAlign="center" maxWidth={{ md: '909px', xs: 'none' }}>
          <Typography
            variant="h2"
            color="common.white"
            fontWeight={700}
            mb={3}
            fontSize={{ xs: '24px', md: '60px' }}
          >
            {title}
          </Typography>
          <Typography color="common.white">{version}</Typography>
        </Box>
      </Stack>

      <Stack
        direction={{ md: 'row', xs: 'column' }}
        justifyContent="space-between"
        gap={6}
        sx={{
          px: { lg: '100px', xs: 2 },
          py: { lg: 12, xs: 5 },
        }}
      >
        <Stack
          maxWidth={{ md: '200px', xs: 'none' }}
          width="100%"
          gap={2}
          textAlign={{ xs: 'center', md: 'left' }}
        >
          <Typography
            color={'textSecondary'}
            onClick={() => navigate('/policy/terms')}
            fontWeight={currentPolicy === 'terms' ? 700 : 500}
            sx={{ cursor: 'pointer' }}
          >
            Terms of Use
          </Typography>
          <Typography
            color={'textSecondary'}
            onClick={() => navigate('/policy/privacy')}
            fontWeight={
              !currentPolicy || currentPolicy === 'privacy' ? 700 : 500
            }
            sx={{ cursor: 'pointer' }}
          >
            Privacy Policy
          </Typography>
          <Typography
            color={'textSecondary'}
            onClick={() => navigate('/policy/anti-policy')}
            fontWeight={currentPolicy === 'anti-policy' ? 700 : 500}
            sx={{ cursor: 'pointer' }}
          >
            Anti-Bribery, Anti-Corruption & Anti-Money Laundering Policy
          </Typography>
        </Stack>
        <RouterOutlet />
      </Stack>
    </Box>
  );
};

export default PolicyLayout;
