import React from 'react';
import { Box } from '@mui/material';

import Helmet from '@component/Helmet';
import Content from '@page/PrivacyPolicy/Content';

const PrivacyPolicy = () => (
  <Box>
    <Helmet
      title="Privacy Policy"
      description="ZinariPay's Privacy Policy outlines how we collect, use, and protect your personal data. Learn more about our commitment to data security and compliance with privacy laws."
      keywords="zinari, zinari coin, zina, zinapay, zinaricoin, zinarifinance, zinaricoin, zinari finance, zinari payment, zinari nigeria, zinari corp, zinari technologies nigeria limited, Privacy Policy, data protection, personal data, ZinariPay, data security, compliance, information privacy"
    />
    <Content />
  </Box>
);

export default PrivacyPolicy;
