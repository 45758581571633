import { Box, Typography } from '@mui/material';

import globalMap from '@image/globalmap.webp';

const Globally = () => {
  return (
    <Box
      sx={{
        background: `url(${globalMap}) no-repeat bottom center`,
        bgcolor: 'secondary.50',
        backgroundSize: 'contain',
        height: {
          xs: '400px',
          lg: '623px',
        },
        pt: { md: 11, xs: 6 },
        px: 2,
      }}
      textAlign="center"
    >
      <Box marginX={'auto'} maxWidth={{ md: '596px', xs: 'none' }}>
        <Typography variant="h4" gutterBottom fontWeight={500}>
          Start receiving payment globally
        </Typography>
        <Typography>
          Join us on the path to revolutionizing how you accept, send, and
          process payments with our global payment solution
        </Typography>
      </Box>
    </Box>
  );
};

export default Globally;
