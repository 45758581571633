import { Box, Button, Stack, Typography } from '@mui/material';
import React from 'react';

import hero from '@image/hero.webp';
import { CONSOLE_URL } from '@variable';

const Hero = () => {
  return (
    <Box
      sx={{
        background: `url(${hero})`,
        backgroundSize: 'cover',
        bgcolor: 'primary.900',
        backgroundPosition: 'center right',
        height: { lg: '100vh', xs: 'fit-content' },
        maxHeight: { lg: '900px', xs: 'none' },
        pb: 20,
        pt: { md: 20, xs: 15 },
        px: { lg: '100px', xs: 2 },
        position: 'relative',
      }}
      id="LandingHero"
    >
      <Stack gap={4}>
        <Typography
          variant="h2"
          color="common.white"
          fontWeight={700}
          maxWidth={580}
        >
          Your Gateway to Seamless Crypto{' '}
          <Box component="span" sx={{ color: 'secondary.400' }}>
            Payments
          </Box>
        </Typography>

        <Typography variant="body1" color="common.white" maxWidth={450}>
          Fast, secure, and hassle-free. ZinariPay lets business receive
          cryptocurrency payments instantly.
        </Typography>

        <Stack direction={'row'} gap={4}>
          <Button
            variant="contained"
            sx={{
              textTransform: 'none',
              bgcolor: 'white !important',
              color: 'primary.900',
            }}
            href={CONSOLE_URL}
            target={'_blank'}
          >
            Create an account
          </Button>
          <Button
            variant="outlined"
            color="primary"
            sx={{
              color: 'common.white',
              background: { sm: 'rgba(0,0,0,0.4)', md: 'transparent' },
            }}
            href="mailto:sales@zinari.io"
          >
            Contact Sales
          </Button>
        </Stack>
      </Stack>
      <Box
        id="headerTicker"
        sx={{ position: 'absolute', bottom: 0, width: '100%' }}
      />
    </Box>
  );
};

export default Hero;
