import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Typography,
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import React, { useState } from 'react';

const CustomAccordion = ({ values, styles = {} }: any) => {
  const [expanded, setExpanded] = useState<number>();

  const handleChange =
    (panel: number) => (event: React.SyntheticEvent, isExpanded: boolean) => {
      setExpanded(isExpanded ? panel : undefined);
    };

  return (
    <>
      {values.map(({ title, summary }: any, index: number) => (
        <Accordion
          key={title}
          sx={{
            color: 'common.white',
            bgcolor: 'transparent',
            borderColor: 'common.white',
            borderBottom: '1px solid',
            ...styles,
          }}
          expanded={expanded === index}
          onChange={handleChange(index)}
          elevation={0}
        >
          <AccordionSummary
            expandIcon={
              <ExpandMoreIcon sx={{ color: styles.color || 'common.white' }} />
            }
            aria-controls="panel1-content"
            id="panel1-header"
          >
            <Typography fontSize="20px" fontWeight={500}>
              {title}
            </Typography>
          </AccordionSummary>
          <AccordionDetails>{summary}</AccordionDetails>
        </Accordion>
      ))}
    </>
  );
};

export default CustomAccordion;
