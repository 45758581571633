import { Box, Stack, Typography } from '@mui/material';
import React from 'react';

import Fees from './Fees';

const Hero = () => {
  return (
    <Box
      sx={{
        px: { lg: '100px', xs: 2 },
        py: { md: 20, xs: 10 },
        display: 'flex',
        flexDirection: { xs: 'column', md: 'row' },
        alignItems: { md: 'flex-start', xs: 'center' },
        justifyContent: 'space-between',
        gap: 9,
      }}
    >
      <Stack justifyContent={'flex-start'}>
        <Typography
          variant="h2"
          fontWeight={700}
          sx={{ margin: 'auto', px: 1, mb: 4 }}
          maxWidth={{ md: '800px', xs: 'none' }}
        >
          Simple Pricing built
          <br />
          for businesses of
          <br />
          all sizes
        </Typography>
        <Typography
          textAlign="left"
          sx={{ px: 1, mb: 6 }}
          maxWidth={{ md: '550px', xs: 'none' }}
          color="textSecondary"
        >
          ZinariPay offers simple, transparent pricing for all businesses,
          <br />
          ensuring no hidden fees and easy management of payment
          <br />
          solutions.
        </Typography>
      </Stack>
      <Box maxWidth={'538px'} width={'100%'}>
        <Fees fee={'3%'} description={'On all transactions'} />
      </Box>
    </Box>
  );
};

export default Hero;
