import { Box, Button, Stack, Typography } from '@mui/material';
import React from 'react';

import image from '@image/grow.webp';
import { CONSOLE_URL } from '@variable';

const VALUES = [
  {
    title: 'KYC & Compliance',
    description:
      'ZinariPay streamlines the KYC process, allowing you to easily verify user identities while staying fully compliant with global regulations.',
  },
  {
    title: 'Scalability',
    description:
      'Scale your business with confidence, knowing that our payment gateway can handle increasing transaction volumes without compromising performance.',
  },
  {
    title: 'Detailed Analytics and Reporting',
    description:
      'Gain valuable insights into your transactions with our comprehensive analytics and reporting tools',
  },
  {
    title: 'Developer-Friendly Environment',
    description:
      'Build and integrate with ease using our developer-centric tools and resources.',
  },
  {
    title: '24/7 Support',
    description:
      'Access dedicated support whenever you need it, with our around-the-clock customer service.',
  },
];

const Grow = () => {
  return (
    <Box
      sx={{
        px: { lg: '100px', xs: 2 },
        py: { lg: 15, xs: 5 },
        background: 'rgba(223, 239, 249, 0.20)',
      }}
    >
      <Stack
        direction={{ md: 'row', xs: 'column' }}
        justifyContent="space-between"
      >
        <Box>
          <Typography variant="body1" color="textSecondary">
            Solutions
          </Typography>

          <Typography variant="h4" fontWeight={600} mt={3} mb={7}>
            Grow your business and thrive in any market with ZinariPay&apos;s
          </Typography>

          {VALUES.map(({ title, description }, index) => (
            <Box
              key={title}
              px={3}
              sx={{ position: 'relative', maxWidth: '555px' }}
            >
              {!index && (
                <Box
                  sx={{
                    background:
                      'linear-gradient(90deg, #0C2A35 10.78%, #0FD07D 99.88%)',
                    height: '100%',
                    width: '5px',
                    position: 'absolute',
                    left: 0,
                    top: 0,
                  }}
                ></Box>
              )}
              <Typography variant="h5" fontWeight={600}>
                {title}
              </Typography>
              <Typography mt={2} mb={4}>
                {description}
              </Typography>
            </Box>
          ))}

          <Button
            variant={'contained'}
            color={'primary'}
            href={CONSOLE_URL}
            target={'_blank'}
          >
            Get Started
          </Button>
        </Box>

        <Box maxWidth={{ md: '440px', xs: 'none' }} pt={{ md: 20, xs: 6 }}>
          <img
            src={image}
            alt="grow with ZinariPay"
            width={'100%'}
            height={'auto'}
          />
        </Box>
      </Stack>
    </Box>
  );
};

export default Grow;
