import { Box, Button, Typography } from '@mui/material';

import background from '@image/developer-back.webp';

const ContactSales = () => {
  return (
    <Box sx={{ my: 10, px: { lg: '100px', xs: 2 } }}>
      <Box
        sx={{
          background: `url(${background}) no-repeat`,
          backgroundSize: 'cover',
          backgroundPosition: 'top right',
          height: { xs: 'fit-content' },
          maxHeight: { lg: '900px', xs: 'none' },
          maxWidth: { md: '980px', xs: 'none' },
          py: 12.5,
          px: { lg: '100px', xs: 2 },
          margin: 'auto',
          borderRadius: '20px',
          textAlign: 'center',
        }}
      >
        <Typography variant="h4" color="common.white" fontWeight={500} mb={5}>
          Contact our sales team for partnership{' '}
          <Box component="span" sx={{ color: 'secondary.400' }}>
            opportunities
          </Box>{' '}
          with ZinariPay.
        </Typography>

        <Button
          variant="outlined"
          color="primary"
          size="large"
          sx={{
            bgcolor: 'common.white',
            border: 0,
            borderRadius: '15px',
            textTransform: 'none',
          }}
          href="mailto:sales@zinari.io"
        >
          sales@zinari.io
        </Button>
      </Box>
    </Box>
  );
};

export default ContactSales;
