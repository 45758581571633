import React from 'react';
import './index.scss';
import { CssBaseline, ThemeProvider } from '@mui/material';
import ReactDOM from 'react-dom/client';

import { TawkToProvider } from '@hook/useTawkTo.hook';
import Helmet from '@component/Helmet';

import App from './App';
import reportWebVitals from './reportWebVitals';
import theme from './theme';

// eslint-disable-next-line import/no-named-as-default-member
const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement,
);

root.render(
  <ThemeProvider theme={theme}>
    <CssBaseline />
    <Helmet />
    <TawkToProvider>
      <App />
    </TawkToProvider>
  </ThemeProvider>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
