import {
  Box,
  Button,
  Card,
  CardContent,
  Grid2 as Grid,
  Stack,
  Typography,
} from '@mui/material';
import React from 'react';
import { useNavigate } from 'react-router-dom';

import globalImage from '@icon/global.webp';
import security from '@icon/security.webp';
import payout from '@icon/payout.webp';
import integration from '@icon/integration.webp';

const FEATURES = [
  {
    icon: integration,
    title: 'Easy Integration',
    description:
      'Seamlessly integrate with your existing platforms using our flexible CDN, NPM package, APIs and Webhooks.',
  },
  {
    icon: payout,
    title: 'Crypto Payouts',
    description:
      'Receive payments in major cryptocurrencies like USDT and USDC with minimal fees and instant transactions.',
  },
  {
    icon: security,
    title: 'Security',
    description:
      'Protect your transactions with advanced encryption and compliance with global security standards.',
  },
  {
    icon: globalImage,
    title: 'Global Reach',
    description:
      'Operate seamlessly across borders, with support for multiple currencies and compliance with regional regulations.',
  },
];

const Features = () => {
  const navigate = useNavigate();

  return (
    <Box
      sx={{
        px: { lg: '100px', xs: 2 },
        py: 15,
        textAlign: 'center',
      }}
    >
      <Stack alignItems="center" mb={8}>
        <Typography variant="h4" fontWeight={600} gutterBottom>
          Effortless Crypto{' '}
          <Box component="span" sx={{ color: 'secondary.400' }}>
            Payments
          </Box>
        </Typography>

        <Typography variant="body1" maxWidth="571px">
          Discover the powerful solutions that make our cryptocurrency payment
          gateway the perfect solution for your business.
        </Typography>
      </Stack>

      <Grid container spacing={4} mb={5}>
        {FEATURES.map(({ title, description, icon }, index) => (
          <Grid size={{ md: 6, xs: 12 }} key={title}>
            <Card
              sx={{
                maxWidth: '569px',
                width: '100%',
                borderLeft: '5px solid',
                borderColor: 'secondary.400',
                height: '100%',
                float: index % 2 !== 0 ? 'right' : 'none',
              }}
            >
              <CardContent
                sx={{
                  textAlign: 'left',
                  pt: { xs: 1, md: 3 },
                  pb: { xs: 1, md: 5 },
                  pr: { xs: 3, md: '60px' },
                  pl: { xs: 3, md: '68px' },
                }}
              >
                <img
                  src={icon}
                  alt={title}
                  style={{ height: 58, width: 'auto' }}
                ></img>
                <Typography variant="h5" fontWeight="500" pt={'20px'} pb={2}>
                  {title}
                </Typography>
                <Typography>{description}</Typography>
              </CardContent>
            </Card>
          </Grid>
        ))}
      </Grid>

      <Button
        variant="contained"
        color="primary"
        onClick={() => navigate('/solutions')}
      >
        Learn More
      </Button>
    </Box>
  );
};

export default Features;
