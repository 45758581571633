import { Box, Typography } from '@mui/material';

import hero from '../images/hero.webp';

const Hero = () => {
  return (
    <>
      <Typography
        variant="h2"
        maxWidth={{ md: '800px', xs: 'none' }}
        fontWeight={700}
        textAlign="center"
        sx={{ margin: 'auto', px: 1 }}
      >
        ZinariPay makes receiving crypto{' '}
        <Box component="span" sx={{ color: 'secondary.400' }}>
          payments
        </Box>{' '}
        fast, secure, and simple.
      </Typography>

      <Box
        sx={{
          px: 2,
        }}
      >
        <Box
          sx={{
            background: `url(${hero}) no-repeat top left`,
            backgroundSize: 'cover',
            height: { md: '530px', xs: '300px' },
            mt: 7,
            mb: 13,
            borderRadius: '15px',
          }}
        />
      </Box>
    </>
  );
};

export default Hero;
