import { Box, Button, Stack, Typography } from '@mui/material';
import React from 'react';

import background from '@image/developer-back.webp';
import { CONSOLE_URL } from '@variable';
import CustomAccordion from '@component/Accordion';

const ACCORDION = [
  {
    title: 'Our Mission',
    summary:
      'To revolutionize the way businesses manage cryptocurrency transactions with security, ease of integration, and global reach at the forefront of our solutions.',
  },
  {
    title: 'Our Vision',
    summary:
      'We aim to become the leading cryptocurrency payment gateway, empowering businesses of all sizes to operate globally with the utmost confidence in the security and efficiency of their transactions.',
  },
  {
    title: 'Our Values',
    summary:
      'Innovation, transparency, and customer-centricity are at the core of everything we do. We are committed to continuously improving our platform to meet the evolving needs of our clients.',
  },
  {
    title: 'Our Impact and Ambitions',
    summary:
      'As we prepare to launch, our ambition is to make a significant impact in the cryptocurrency payment industry. We are committed to continuous innovation and contributing to the growth and success of businesses around the world.',
  },
  {
    title: 'Why Choose Us',
    summary:
      'Our platform offers unmatched security, ease of integration, and a commitment to customer satisfaction. We stand out in the industry by delivering solutions that empower businesses to grow and succeed in the digital economy.',
  },
];

const WhoIsZinari = () => {
  return (
    <Box
      sx={{
        background: `url(${background})`,
        backgroundSize: 'cover',
        backgroundPosition: 'center right',
        height: { xs: 'fit-content' },
        maxHeight: { lg: '900px', xs: 'none' },
        py: 12.5,
        px: { lg: '100px', xs: 2 },
      }}
    >
      <Stack
        direction={{ md: 'row', xs: 'column' }}
        justifyContent="space-between"
        spacing={4}
      >
        <Box maxWidth={{ md: '485px', xs: 'none' }}>
          <Typography variant="body1" color="common.white" fontSize="18px">
            Who is Zinari
          </Typography>
          <Typography
            variant="h4"
            color="common.white"
            mb={4}
            mt={2}
            fontWeight={500}
          >
            Join us on the path to revolutionizing how you accept, send, and
            process payments today!
          </Typography>
          <Button
            variant="contained"
            color="primary"
            href={CONSOLE_URL}
            target={'_blank'}
          >
            Get Started
          </Button>
        </Box>

        <Box maxWidth={{ md: '620px', xs: 'none' }} width="fill-available">
          <CustomAccordion values={ACCORDION} />
        </Box>
      </Stack>
    </Box>
  );
};

export default WhoIsZinari;
