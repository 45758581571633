import { Box, Stack, Typography } from '@mui/material';

import image from '@image/why.webp';

const VALUES = [
  {
    title: 'Top-Notch Security',
    description:
      'We prioritize your safety with advanced encryption and compliance with global security standards.',
  },
  {
    title: 'Scalable Solutions',
    description:
      'Our gateway grows with your business, handling high transaction volumes without a hitch.',
  },
  {
    title: '24/7 Customer Support',
    description:
      'We’re here for you around the clock, offering expert support whenever you need it.',
  },
  {
    title: 'Trusted by Businesses',
    description:
      'Join the growing number of businesses that rely on us for secure and efficient payments.',
  },
];

const Why = () => {
  return (
    <Box
      sx={{
        px: { lg: '100px', xs: 2 },
        py: { lg: 15, xs: 5 },
        background: 'rgba(223, 239, 249, 0.20)',
      }}
    >
      <Stack
        direction={{ md: 'row', xs: 'column' }}
        justifyContent="space-between"
        gap={6}
      >
        <Box maxWidth={{ md: '560px', xs: 'none' }}>
          <Typography variant="h4" fontWeight={600}>
            Why Choose ZinariPay?
          </Typography>
          <Typography variant="body1" mt={3} mb={4}>
            Discover the benefits of choosing our gateway, designed to empower
            your business with secure, scalable, and reliable payment solutions.
          </Typography>

          {VALUES.map(({ title, description }, index) => (
            <Box key={title} px={3} sx={{ position: 'relative' }}>
              {!index && (
                <Box
                  sx={{
                    background:
                      'linear-gradient(90deg, #0C2A35 10.78%, #0FD07D 99.88%)',
                    height: '100%',
                    width: '5px',
                    position: 'absolute',
                    left: 0,
                    top: 0,
                  }}
                ></Box>
              )}
              <Typography variant="h5" fontWeight={600}>
                {title}
              </Typography>
              <Typography mt={2} mb={4}>
                {description}
              </Typography>
            </Box>
          ))}
        </Box>

        <Box maxWidth={{ md: '554px', xs: 'none' }}>
          <img src={image} alt="why zinari" width={'100%'} height={'auto'} />
        </Box>
      </Stack>
    </Box>
  );
};

export default Why;
