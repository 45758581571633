import React from 'react';
import { createBrowserRouter } from 'react-router-dom';

import Layout from '@component/Layout';
import Landing from '@page/Landing';
import Pricing from '@page/Pricing';
import ErrorBoundary from '@component/ErrorBoundary';
import PrivacyPolicy from '@page/PrivacyPolicy';
import AntiPolicy from '@page/AntiPolicy';
import Developers from '@page/Developers';
import PayLink from '@page/PayLink';
import WhyZinari from '@page/WhyZinari';
import Solutions from '@page/Solutions/Solutions';
import Company from '@page/Company';
import Support from '@page/Support';
import PolicyLayout from '@component/PolicyLayout';
import Terms from '@page/Terms';

const router = createBrowserRouter([
  {
    path: '',
    element: (
      <ErrorBoundary>
        <Layout />
      </ErrorBoundary>
    ),
    children: [
      {
        path: '',
        element: <Landing />,
      },
      {
        path: 'why-zinari',
        element: <WhyZinari />,
      },
      {
        path: 'solutions',
        element: <Solutions />,
      },
      {
        path: 'developers',
        element: <Developers />,
      },
      {
        path: 'support',
        element: <Support />,
      },
      {
        path: 'company',
        element: <Company />,
      },
      {
        path: 'paylink',
        element: <PayLink />,
      },
      {
        path: 'pricing',
        element: <Pricing />,
      },
      {
        path: 'policy',
        element: <PolicyLayout />,
        children: [
          {
            path: '',
            element: <PrivacyPolicy />,
          },
          {
            path: 'privacy',
            element: <PrivacyPolicy />,
          },
          {
            path: 'terms',
            element: <Terms />,
          },
          {
            path: 'anti-policy',
            element: <AntiPolicy />,
          },
        ],
      },
      {
        path: '*',
        element: <Landing />,
      },
    ],
  },
]);

export default router;
