const {
  REACT_APP_TAWKTO_PROPERTY_ID,
  REACT_APP_TAWKTO_WIDGET_ID,
  REACT_APP_CONSOLE_URL,
} = process.env;

export const TAWKTO_PROPERTY_ID = REACT_APP_TAWKTO_PROPERTY_ID as string;
export const TAWKTO_WIDGET_ID = REACT_APP_TAWKTO_WIDGET_ID as string;
export const CONSOLE_URL = REACT_APP_CONSOLE_URL as string;
export const IS_DEV = process.env.NODE_ENV === 'development';

export const TEAM_MEMBERS = [
  {
    name: 'Christopher Akanmu',
    role: 'Founder',
    image: '/chris.webp',
    linkedin: 'https://www.linkedin.com/in/christopher-akanmu-1b9438153/',
  },
  {
    name: 'Francesca Akanmu',
    role: 'Manager, Financial Planning and Analysis (FP&A)',
    image: '/francesca.webp',
    linkedin: 'https://www.linkedin.com/in/francesca-akanmu-8ba77013a',
  },
  {
    name: 'Mercy M Johnson',
    role: 'Lead Product Designer',
    image: '/mercy.webp',
    linkedin: 'https://www.linkedin.com/in/mercymunajohnson/',
  },
  {
    name: 'Divine Aneke',
    role: 'Brand Designer',
    image: '/divine.webp',
    linkedin: 'https://www.linkedin.com/in/divine-aneke-b3129b242/',
  },
];

export const FIAT_CURRENCIES = [
  // African
  { name: 'Nigerian Naira', code: 'NGN', symbol: '₦' },
  { name: 'South African Rand', code: 'ZAR', symbol: 'R' },
  // Asian
  { name: 'Indian Rupee', code: 'INR', symbol: '₹' },
  // America
  { name: 'United States Dollar', code: 'USD', symbol: '$' },
  // Europe
  { name: 'Euro', code: 'EUR', symbol: '€' },
  { name: 'British Pound', code: 'GBP', symbol: '£' },
  { name: 'Canadian Dollar', code: 'CAD', symbol: '$' },
  // Middle east
  { name: 'United Arab Emirates Dirham', code: 'AED', symbol: 'د.إ' },
];
